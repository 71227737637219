import Icon from '@components/Icon'
import { Dispatch, ReactNode, SetStateAction, useCallback } from 'react'
import ReactModal from 'react-modal'

type DialogModalProps = {
  confirmFunction: () => void
  isOpenModal: boolean
  titleText: string
  bodyComponent: ReactNode
}

const DialogModal = ({
  isOpenModal,
  confirmFunction,
  titleText,
  bodyComponent,
}: DialogModalProps) => {
  
  const onClose = useCallback(() => {
    confirmFunction()
  }, [confirmFunction])

  return (
    <ReactModal
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      className=""
      overlayClassName="dialog-overlay"
      isOpen={isOpenModal}
    >
      <>
        <div className="dialog medium">
          <button className="dialog-close" onClick={onClose}>
            <Icon name="close" size={24} />
          </button>
          <div className="dialog-title">{titleText}</div>
          <div className="dialog-body">{bodyComponent}</div>
          <div className="dialog-footer button-group gapless">
            <button type="button" className="btn btn-primary confirm-button" onClick={onClose}>
              Ok
            </button>
          </div>
        </div>
      </>
    </ReactModal>
  )
}

export default DialogModal
