import { useMemo } from 'react'
import { Control } from 'react-hook-form'
import FormInput from './FormInput'
import FormDateInput from './FormDateInput'
import transactionUtils from '@utils/transaction'
import { NewTxDetailsFormType } from '@customTypes/transaction'
import FormCountrySelectorWithSearch from '@components/ui/formCountrySelectorWithSearch/FormCountrySelectorWithSearch'
import FormSelector from './CustomSelector/CustomSelector'
import { selectStyles } from './CustomSelector/styleConfig'

type CommonSenderFormFieldProps = {
  name: string
  control: Control<NewTxDetailsFormType>
}

const CommonSenderFormField = ({ name, control }: CommonSenderFormFieldProps) => {
  const renderView = useMemo(() => {
    switch (name) {
      // case 'code': {
      //   return (
      //     <FormInput<NewTxDetailsFormType>
      //       name="sender.code"
      //       control={control}
      //       placeholder="Identification code"
      //       label="Identification code"
      //       type="text"
      //       maxLength={32}
      //     />
      //   )
      // }
      case 'email': {
        return (
          <FormInput<NewTxDetailsFormType>
            name="sender.email"
            control={control}
            placeholder="Email"
            label="Email"
            type="text"
            maxLength={64}
          />
        )
      }
      case 'date_of_birth': {
        return (
          <FormDateInput<NewTxDetailsFormType>
            name="sender.date_of_birth"
            control={control}
            label="Date of birth"
          />
        )
      }
      case 'country_of_birth_iso_code': {
        return (
          <FormCountrySelectorWithSearch<NewTxDetailsFormType>
            name="sender.country_of_birth_iso_code"
            control={control}
            options={transactionUtils.countriesOptions}
            labelName="Country of birth"
          />
        )
      }
      case 'address': {
        return (
          <FormInput<NewTxDetailsFormType>
            name="sender.address"
            control={control}
            placeholder="Address"
            label="Address"
            type="text"
            maxLength={64}
          />
        )
      }
      case 'city': {
        return (
          <FormInput<NewTxDetailsFormType>
            name="sender.city"
            control={control}
            placeholder="City"
            label="City"
            type="text"
            maxLength={64}
          />
        )
      }
      case 'country_iso_code': {
        return (
          <FormCountrySelectorWithSearch<NewTxDetailsFormType>
            options={transactionUtils.countriesOptions}
            name="sender.country_iso_code"
            control={control}
            labelName="Country of residence"
          />
        )
      }
      case 'nationality_country_iso_code': {
        return (
          <FormCountrySelectorWithSearch<NewTxDetailsFormType>
            options={transactionUtils.countriesOptions}
            name="sender.nationality_country_iso_code"
            control={control}
            labelName="Country of nationality"
          />
        )
      }
      case 'id_type': {
        return (
          <FormSelector<NewTxDetailsFormType>
          options={transactionUtils.idTypeOptions}
          name="sender.id_type"
          control={control}
          styles={selectStyles}
          labelName="ID type"
        />
        )
      }
    }
  }, [control, name])
  return renderView
}

export default CommonSenderFormField
