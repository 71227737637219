import { transactionActionCreators } from '@actionCreators/transactionActionCreators'
import Icon from '@components/Icon'
import {
  BeneficiaryForNewTxDetailsForm,
  CreditPartyIdentifierForNewTxDetailsForm,
  SenderForNewTxDetailsForm,
} from '@customTypes/transaction'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { clearCurrentTxDetails } from '@sliceActionCreators/transactionSlice'
import dateUtils from '@utils/date'
import transactionUtils from '@utils/transaction'
import omit from 'lodash/omit'
import { Fragment, useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'

const TransactionDetailsPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  const { currentTxDetails, getTransactionDetailsByIdLoadState } = useAppSelector(
    state => state.transaction
  )

  useEffect(() => {
    if (id) {
      dispatch(transactionActionCreators.getTransactionDetailsById(id))
    } else {
      navigate('/transactions')
    }

    return () => {
      dispatch(clearCurrentTxDetails())
    }
  }, [dispatch, id, navigate])

  const onViewTransactionsClick = useCallback(() => {
    navigate('/transactions')
  }, [navigate])

  const onTxLogsClick = useCallback(() => {
    id && dispatch(transactionActionCreators.getTransactionLogsById(id))
  }, [dispatch, id])

  return (
    <>
      {getTransactionDetailsByIdLoadState.isLoading && (
        <div className="spinner spinner-fixed">
          <BeatLoader size={30} color="#3171d8" />
        </div>
      )}
      <section className="form-section">
        <h3 className="t-center mb-40">Transaction Details</h3>
        <div className="container container-lg">
          <div className="background-container d-flex justify-content-center">
            <div className="review-container box is-transparent d-flex">
              <div className="review-dialog-wrapper">
                <div className="review-dialog box is-white d-flex flex-col gap-16 f-monrope-sm fw-500">
                  <div>
                    <div className="h5 mb-8">Transaction Details</div>
                    <hr />
                  </div>

                  <div>
                    <div>
                      <div className="form-row mb-8">
                        <div className="d-flex align-items-center gap-8">
                          Sent Amount <Icon name="arrow-left" size={16} color="#0C9B00" />
                        </div>
                      </div>
                      <div className="form-row mb-8">
                        <div className="h3">
                          {currentTxDetails ? (
                            <>
                              <span style={{ color: '#0C9B00' }}>+&nbsp;</span>
                              <span>
                                {currentTxDetails.source.amount.toFixed(2)}{' '}
                                {currentTxDetails.source.currency}
                              </span>
                            </>
                          ) : (
                            <>-</>
                          )}
                        </div>
                        <div>Status</div>
                      </div>

                      <div className="form-row mb-8">
                        <div className="d-flex align-items-center gap-8">
                          Receive Amount <Icon name="arrow-right" size={16} color="#B91C1C" />
                        </div>
                        <div className="d-flex align-items-center gap-4 h5">
                          {currentTxDetails ? (
                            <>
                              <Icon
                                name="ellipse"
                                size={10}
                                color={transactionUtils.getColorByTxStatus(currentTxDetails.status)}
                              />{' '}
                              {transactionUtils.getTxStatus(currentTxDetails.status)}
                            </>
                          ) : (
                            <>-</>
                          )}
                        </div>
                      </div>
                      <div className="h3" style={{ color: '#B91C1C' }}>
                        {currentTxDetails ? (
                          <>
                            <span>-&nbsp;</span>
                            {currentTxDetails.destination.amount.toFixed(2)}{' '}
                            {currentTxDetails.destination.currency}
                          </>
                        ) : (
                          <>-</>
                        )}
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="mb-4">Time and Date</p>
                      {/* <p className="fw-700">10:54pm, 05/23/2024</p> */}
                      <p className="fw-700">
                        {currentTxDetails ? (
                          <>
                            {dateUtils.getFormattedDate(
                              currentTxDetails.creationDate,
                              'hh:mmaaa, dd/MM/yyyy'
                            )}
                          </>
                        ) : (
                          <>-</>
                        )}
                      </p>
                    </div>
                    <div className="transaction-id">
                      <p className="mb-4">Transaction ID</p>
                      <p className="fw-700">{currentTxDetails ? currentTxDetails.id : '-'}</p>
                    </div>
                  </div>

                  <hr />

                  <div>
                    <dl className="definition-list gap-8">
                      <dt>Payout Type</dt>
                      <dd>{currentTxDetails ? currentTxDetails.payer.service.name : '-'}</dd>

                      <dt>Payout Method</dt>
                      <dd>{currentTxDetails ? currentTxDetails.payer.name : '-'}</dd>

                      <dt>Payout Fee</dt>
                      <dd>
                        {currentTxDetails
                          ? `${currentTxDetails.totalFee.toFixed(2)} ${
                              currentTxDetails.source.currency
                            }`
                          : '-'}
                      </dd>
                    </dl>
                  </div>

                  <hr />

                  <div className="mb-8">
                    <h4 className="mb-8">Sender</h4>
                    <hr />
                  </div>

                  <div>
                    <dl className="definition-list gap-8">
                      <dt>First and last name</dt>
                      <dd>
                        {currentTxDetails
                          ? `${currentTxDetails.sender.firstname} ${currentTxDetails.sender.lastname}`
                          : '-'}
                      </dd>
                      <dt>ID number</dt>
                      <dd>
                        {currentTxDetails && currentTxDetails.sender.id_number
                          ? `${currentTxDetails.sender.id_number}`
                          : '-'}
                      </dd>

                      {currentTxDetails &&
                        Object.keys(
                          omit(currentTxDetails.sender, [
                            'firstname',
                            'lastname',
                            'id',
                            'firstName',
                            'lastName',
                            'id_number',
                            'code'
                          ])
                        ).map(keyName => (
                          <Fragment key={keyName}>
                            {currentTxDetails.sender[
                              keyName as keyof SenderForNewTxDetailsForm
                            ] && (
                              <>
                                <dt>{transactionUtils.getLabelOfTxDetails(keyName)}</dt>
                                <dd>
                                  {
                                    currentTxDetails.sender[
                                      keyName as keyof SenderForNewTxDetailsForm
                                    ]
                                  }
                                </dd>
                              </>
                            )}
                          </Fragment>
                        ))}
                    </dl>
                  </div>

                  <div className="mb-8">
                    <h4 className="mb-8">Recipient</h4>
                    <hr />
                  </div>

                  <div>
                    <dl className="definition-list gap-8">
                      <dt>First and last name</dt>
                      <dd>
                        {currentTxDetails ? (
                          <>
                            {currentTxDetails.beneficiary.firstname}{' '}
                            {currentTxDetails.beneficiary.lastname}
                          </>
                        ) : (
                          <>-</>
                        )}
                      </dd>

                      {currentTxDetails &&
                        Object.keys(
                          omit(currentTxDetails.beneficiary, ['firstname', 'lastname'])
                        ).map(keyName => (
                          <Fragment key={keyName}>
                            {currentTxDetails.beneficiary[
                              keyName as keyof BeneficiaryForNewTxDetailsForm
                            ] && (
                              <>
                                <dt>{transactionUtils.getLabelOfTxDetails(keyName)}</dt>
                                <dd>
                                  {
                                    currentTxDetails.beneficiary[
                                      keyName as keyof BeneficiaryForNewTxDetailsForm
                                    ]
                                  }
                                </dd>
                              </>
                            )}
                          </Fragment>
                        ))}

                      {currentTxDetails &&
                        Object.keys(currentTxDetails.creditPartyIdentifier).map(keyName => (
                          <Fragment key={keyName}>
                            {currentTxDetails.creditPartyIdentifier[
                              keyName as keyof CreditPartyIdentifierForNewTxDetailsForm
                            ] && (
                              <>
                                <dt>{transactionUtils.getLabelOfTxDetails(keyName)}</dt>
                                <dd>
                                  {
                                    currentTxDetails.creditPartyIdentifier[
                                      keyName as keyof CreditPartyIdentifierForNewTxDetailsForm
                                    ]
                                  }
                                </dd>
                              </>
                            )}
                          </Fragment>
                        ))}
                    </dl>
                  </div>

                  <hr />
                  <div>
                    <dl className="definition-list gap-8">
                      <dt>Purpose of transfer</dt>
                      <dd>
                        {currentTxDetails
                          ? transactionUtils.getPurposeOfRemittanceTextValue(
                              currentTxDetails.purposeOfRemittance
                            )
                          : '-'}
                        {currentTxDetails?.reference && (
                        <>
                          <dt>Reference</dt>
                          <dd>{currentTxDetails?.reference}</dd>
                        </>
                      )}
                      </dd>
                    </dl>
                  </div>

                  <hr />

                  <div className="button-group flex-wrap">
                    {/* <button type="button" className="btn btn-tertiary" onClick={onClickModal}>
                    Cancel
                  </button>

                  <button type="button" className="btn btn-secondary outline" onClick={onClickBack}>
                    Back
                  </button> */}

                    <button
                      type="button"
                      className="btn btn-primary confirm-button"
                      onClick={onViewTransactionsClick}
                    >
                      View Transactions
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button type="button" className="btn btn-primary confirm-button" onClick={onTxLogsClick}>
          View Transaction Logs
        </button>
      </section>
    </>
  )
}

export default TransactionDetailsPage
