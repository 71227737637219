import { FileType } from '@customTypes/common'
import { NewTxDetailsFormType } from '@customTypes/transaction'
import commonUtils from '@utils/common'
import dateUtils from '@utils/date'
import { Resolver } from 'react-hook-form'
import * as yup from 'yup'

type Config = {
  creditPartyIdentifier: Array<string>
  sender: Array<string>
  beneficiary: Array<string>
}

const newTxDetailsFormScheme = (config: Config | null, currentServiceId?: number): yup.ObjectSchema<NewTxDetailsFormType> => {
  let senderScheme = yup.object({
    firstname: yup
      .string().trim()
      .typeError('Required field')
      .required('Required field')
      .matches(
        /^[\s]*([a-zA-Z]{1,}[\s]{0,1}[-.`‘’']{0,1}[\s]{0,1})+[a-zA-Z.]*[\s]*$/,
        'Please, use letters only'
      ),
    lastname: yup
      .string().trim()
      .typeError('Required field')
      .required('Required field')
      .matches(
        /^[\s]*([a-zA-Z]{1,}[\s]{0,1}[-.`‘’']{0,1}[\s]{0,1})+[a-zA-Z.]*[\s]*$/,
        'Please, use letters only'
      ),
    id_number: yup
      .string().trim()
      .typeError('Required field')
      .required('Required field')
      .min(7, `Please enter a complete id number.`)
  })
  let beneficiaryScheme = yup.object({
    firstname: yup
      .string().trim()
      .typeError('Required field')
      .required('Required field')
      .matches(
        /^[\s]*([a-zA-Z]{1,}[\s]{0,1}[-.`‘’']{0,1}[\s]{0,1})+[a-zA-Z.]*[\s]*$/,
        'Please, use letters only'
      ),
    lastname: yup
      .string().trim()
      .typeError('Required field')
      .required('Required field')
      .matches(
        /^[\s]*([a-zA-Z]{1,}[\s]{0,1}[-.`‘’']{0,1}[\s]{0,1})+[a-zA-Z.]*[\s]*$/,
        'Please, use letters only'
      ),
  })
  let creditPartyIdentifierScheme = yup.object()

  if (config) {
    //sender
    if (config.sender.includes('id_type')) {
      senderScheme = senderScheme.concat(yup.object({
        id_type: yup
          .string()
          .typeError('Please, select ID type')
          .required('Please, select ID type')
      }))
    }
    // if (config.sender.includes('code')) {
    //   senderScheme = senderScheme.concat(yup.object({
    //     code: yup
    //       .string().trim()
    //       .typeError('Required field')
    //       .required('Required field')
    //   }))
    // }
    if (config.sender.includes('email')) {
      senderScheme = senderScheme.concat(yup.object({
        email: yup
          .string().trim()
          .typeError('Required field')
          .required('Required field')
          .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            'Email is invalid'
          )
      }))
    }

    if (config.sender.includes('date_of_birth')) {
      senderScheme = senderScheme.concat(yup.object({
        date_of_birth: yup
          .string()
          .typeError('Please, enter date of birth')
          .test('validateDate', 'Please, enter a valid date of birth', dateUtils.checkForDateExistence)
          .required('Please, enter date of birth'),
      }))
    }
    if (config.sender.includes('country_of_birth_iso_code')) {
      senderScheme = senderScheme.concat(yup.object({
        country_of_birth_iso_code: yup
          .string()
          .typeError('Please, select country')
          .required('Please, select country'),
      }))
    }
    if (config.sender.includes('country_iso_code')) {
      senderScheme = senderScheme.concat(yup.object({
        country_iso_code: yup
          .string()
          .typeError('Please, select country')
          .required('Please, select country'),
      }))
    }
    if (config.sender.includes('city')) {
      senderScheme = senderScheme.concat(yup.object({
        city: yup
          .string().trim()
          .typeError('Please, enter city')
          .matches(
            /^[\s]*([a-zA-Z]{1,}[\s]{0,1}[-]{0,1}[\s]{0,1}[a-zA-Z]{1,})+[\s]*$/,
            'Please, use only letters'
          )
          .required('Please, enter city'),
      }))
    }
    if (config.sender.includes('address')) {
      senderScheme = senderScheme.concat(yup.object({
        address: yup
          .string()
          .typeError('Please, enter address')
          .matches(
            /^[\s]*([a-zA-Z0-9]{1,}[\s]{0,1}[-,./]{0,2}[\s]{0,1})+[\s]*$/,
            "Please, don't use special characters"
          )
          .required('Please, enter address'),
      }))
    }
    if (config.sender.includes('nationality_country_iso_code')) {
      senderScheme = senderScheme.concat(yup.object({
        nationality_country_iso_code: yup
          .string()
          .typeError('Please, select country of nationality')
          .required('Please, select country of nationality'),
      }))
    }
    if (config.sender.includes('msisdn')) {
      senderScheme = senderScheme.concat(yup.object({
        msisdn: yup
          .string().trim()
          .typeError('Please, enter a valid phone number')
          .test('validatePhoneNumber', 'Please, enter a valid phone number', commonUtils.checkPhoneNumber)
          .required('Required field')
      }))
    }

    //beneficiary
    if (config.beneficiary.includes('id_type')) {
      beneficiaryScheme = beneficiaryScheme.concat(yup.object({
        id_type: yup
          .string()
          .typeError('Please, select ID type')
          .required('Please, select ID type')
      }))
    }
    if (config.beneficiary.includes('id_number')) {
      beneficiaryScheme = beneficiaryScheme.concat(yup.object({
        id_number: yup
          .string().trim()
          .typeError('Required field')
          .required('Required field')
          .min(7, `Please enter a complete id number.`)
      }))
    }
    if (config.beneficiary.includes('code')) {
      beneficiaryScheme = beneficiaryScheme.concat(yup.object({
        code: yup
          .string().trim()
          .typeError('Required field')
          .required('Required field')
      }))
    }
    if (config.beneficiary.includes('email')) {
      beneficiaryScheme = beneficiaryScheme.concat(yup.object({
        email: yup
          .string().trim()
          .typeError('Required field')
          .required('Required field')
          .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            'Email is invalid'
          )
      }))
    }

    if (config.beneficiary.includes('date_of_birth')) {
      beneficiaryScheme = beneficiaryScheme.concat(yup.object({
        date_of_birth: yup
          .string()
          .typeError('Please, enter date of birth')
          .test('validateDate', 'Please, enter a valid date of birth', dateUtils.checkForDateExistence)
          .required('Please, enter date of birth'),
      }))
    }
    if (config.beneficiary.includes('country_of_birth_iso_code')) {
      beneficiaryScheme = beneficiaryScheme.concat(yup.object({
        country_of_birth_iso_code: yup
          .string()
          .typeError('Please, select country')
          .required('Please, select country'),
      }))
    }
    if (config.beneficiary.includes('country_iso_code')) {
      beneficiaryScheme = beneficiaryScheme.concat(yup.object({
        country_iso_code: yup
          .string()
          .typeError('Please, select country')
          .required('Please, select country'),
      }))
    }
    if (config.beneficiary.includes('city')) {
      beneficiaryScheme = beneficiaryScheme.concat(yup.object({
        city: yup
          .string().trim()
          .typeError('Please, enter city')
          .matches(
            /^[\s]*([a-zA-Z]{1,}[\s]{0,1}[-]{0,1}[\s]{0,1}[a-zA-Z]{1,})+[\s]*$/,
            'Please, use only letters'
          )
          .required('Please, enter city'),
      }))
    }
    if (config.beneficiary.includes('address')) {
      beneficiaryScheme = beneficiaryScheme.concat(yup.object({
        address: yup
          .string()
          .typeError('Please, enter address')
          .matches(
            /^[\s]*([a-zA-Z0-9]{1,}[\s]{0,1}[-,./]{0,2}[\s]{0,1})+[\s]*$/,
            "Please, don't use special characters"
          )
          .required('Please, enter address'),
      }))
    }
    if (config.beneficiary.includes('nationality_country_iso_code')) {
      beneficiaryScheme = beneficiaryScheme.concat(yup.object({
        nationality_country_iso_code: yup
          .string()
          .typeError('Please, select country of nationality')
          .required('Please, select country of nationality'),
      }))
    }
    if (config.beneficiary.includes('msisdn') && !config.creditPartyIdentifier.includes('msisdn')) {
      beneficiaryScheme = beneficiaryScheme.concat(yup.object({
        msisdn: yup
          .string()
          .typeError('Please, enter a valid phone number')
          .test('validatePhoneNumber', 'Please, enter a valid phone number', commonUtils.checkPhoneNumber)
          .required('Required field')
      }))
    }

    //creditPartyIdentifier
    if (config.creditPartyIdentifier.includes('msisdn')) {
      creditPartyIdentifierScheme = creditPartyIdentifierScheme.concat(yup.object({
        msisdn: yup
          .string().trim()
          .typeError('Please, enter a valid phone number')
          .test('validatePhoneNumber', 'Please, enter a valid phone number', commonUtils.checkPhoneNumber)
          .required('Required field')
      }))
    }

    if (config.creditPartyIdentifier.includes('iban')) {
      creditPartyIdentifierScheme = creditPartyIdentifierScheme.concat(yup.object({
        iban: yup
          .string().trim()
          .typeError('Please, enter a valid IBAN')
          .matches(
            /^([A-Z]{2}[ '+'\\'+'-]?[0-9]{2})(?=(?:[ '+'\\'+'-]?[A-Z0-9]){9,30}$)((?:[ '+'\\'+'-]?[A-Z0-9]{3,5}){2,7})([ '+'\\'+'-]?[A-Z0-9]{1,3})?$/,
            "Please, enter a valid IBAN"
          )
          .required('Required field')
      }))
    }

    if (config.creditPartyIdentifier.includes('swift_bic_code')) {
      creditPartyIdentifierScheme = creditPartyIdentifierScheme.concat(yup.object({
        swift_bic_code: yup
          .string().trim()
          .typeError('Please, enter a valid SWIFT/BIC code')
          .matches(
            /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/,
            "Please, enter a valid SWIFT/BIC code"
          )
          .required('Required field')
      }))
    }
    if (config.creditPartyIdentifier.includes('bank_account_number')) {
      creditPartyIdentifierScheme = creditPartyIdentifierScheme.concat(yup.object({
        bank_account_number: yup
          .string().trim()
          .typeError('Please, enter a valid bank account number')
          .required('Required field')
          .matches(/^[0-9a-zA-Z]*$/, 'Please, enter a valid bank account number')
          .min(10, `Please, enter a complete bank account number`)
      }))
    }
    if (config.creditPartyIdentifier.includes('ifs_code')) {
      creditPartyIdentifierScheme = creditPartyIdentifierScheme.concat(yup.object({
        ifs_code: yup
          .string().trim()
          .typeError('Please, enter a valid IFSC code')
          .matches(
            /^[A-Z]{4}0[A-Z0-9]{6}$/,
            "Please, enter a valid IFSC code"
          )
          .required('Required field')
      }))
    }
    if (config.creditPartyIdentifier.includes('account_number')) {
      creditPartyIdentifierScheme = creditPartyIdentifierScheme.concat(yup.object({
        account_number: yup
          .string().trim()
          .typeError('Please, enter a valid account number')
          .required('Required field')
          .matches(/^[0-9a-zA-Z]*$/, 'Please, enter a valid account number')
          .min(5, `Please, enter a complete account number`)
      }))
    }
    if (config.creditPartyIdentifier.includes('sort_code')) {
      creditPartyIdentifierScheme = creditPartyIdentifierScheme.concat(yup.object({
        sort_code: yup
          .string().trim()
          .typeError('Please, enter a valid sort code')
          .required('Required field')
          .matches(
            /^\d{6}$|^\d{2}-\d{2}-\d{2}$/,
            "Please, enter a valid sort code"
          )
      }))
    }
  }



  const validationSchema = yup.object({
    sender: senderScheme,
    beneficiary: beneficiaryScheme,
    creditPartyIdentifier: creditPartyIdentifierScheme,
    purposeOfRemittance: yup
      .string()
      .typeError('Please, select purpose of transfer')
      .required('Please, select purpose of transfer'),
    file: yup.mixed<FileType>()
      .required('File is required')
      .test("fileType", "Error. Wrong file type.", (value) =>
        ["image/jpeg", "image/png", "image/gif", 'application/pdf'].includes(value?.type)
      )
      .test("fileSize", "File size must be less than 10Mb", (value) => {
        return value.size <= 10485760
      }),
    reference: yup
      .string().when([], (reference, scheme) => {
        // service id with value 2 is the 'BankAccount' service
        return currentServiceId === 2 ? scheme.required('Required field') : scheme
      })


  })

  return validationSchema
}




const txYupScheme = {
  newTxDetailsFormScheme
}

export default txYupScheme