import AgentBranchesTableHeader from './AgentBranchesTableHeader'
import agentBranchesData from './agentBranchesData'
import AgentBranchesTableRow from './AgentBranchesTableRow'
import { useCallback, useMemo, useState } from 'react'
import DialogModal from '@components/calculation/ui/DialogModal'
import { AgentBranchesDataItem } from '@customTypes/common'
import { CommonIcons } from '@helpers/commonIcons'

const AgentBranchesTable = () => {
  const [isOpenDialogModal, setIsOpenDialogModal] = useState(false)
  const [selectedBranch, setSelectedBranch] = useState<AgentBranchesDataItem | null>(null)

  const bodyComponentForDialogModal = useMemo(
    () => (
      <>
        Quote is valid for 1 day only.
        <br />
        Please pay today at the following branch:
        <p style={{ fontWeight: '700' }}>{selectedBranch?.branch}</p>
        <a
          className="btn-link display-flex align-items-end gap-8"
          href={`https://www.google.com/maps/search/?api=1&query=${selectedBranch?.coordinates}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginBottom: '16px' }}
        >
          <CommonIcons.Location width={18} height={15} color="#0000FF" />
          {selectedBranch?.city}, {selectedBranch?.address}
        </a>
        <br />
        Opening Hours: {selectedBranch?.openingHours}.
      </>
    ),
    [
      selectedBranch?.address,
      selectedBranch?.branch,
      selectedBranch?.city,
      selectedBranch?.coordinates,
      selectedBranch?.openingHours,
    ]
  )

  const onOpenModal = useCallback((currentSelectedBranch: AgentBranchesDataItem) => {
    setSelectedBranch(currentSelectedBranch)

    setIsOpenDialogModal(true)
  }, [])

  const confirmFunction = useCallback(() => {
    setIsOpenDialogModal(false)

    setSelectedBranch(null)
  }, [])
  return (
    <>
      <div className="mz-table tx-table">
        <table>
          <AgentBranchesTableHeader />
          <tbody>
            {agentBranchesData.map((tableDataItem, index) => (
              <AgentBranchesTableRow
                key={index}
                tableDataItem={tableDataItem}
                onOpenModal={onOpenModal}
              />
            ))}
          </tbody>
        </table>
      </div>
      <DialogModal
        isOpenModal={isOpenDialogModal}
        confirmFunction={confirmFunction}
        titleText="Pay in details"
        bodyComponent={bodyComponentForDialogModal}
      />
    </>
  )
}

export default AgentBranchesTable
